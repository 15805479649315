import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { connect } from "react-redux"
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import { setDarkMode } from "../../redux/actions/darkModeAction"
import Logo from '../../img/Logo-transparent.jpg'
import SunIcon from '../../svg/sun.svg'
import MoonIcon from '../../svg/moon.svg'

import './nav.scss'

const links = [
  { url: '/project', label: 'Project' },
  { url: '/about', label: 'About' },
  { url: '/blog', label: 'Blog' }
]

const Nav = ({darkMode, dispatch}) => {
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0]
    if (darkMode) {
      body.className = "dark-mode"
    } else {
      body.className = "light-mode"
    }
  }, [darkMode])

  return (
    <nav className="navbar--wrapper">
      <div className="navbar--image-wrapper">
        <Link to="/">
          <img className="logo-image" src={Logo} alt="Logo"/>
        </Link>
      </div>
      <div className="navbar-text">
        {links.map((data, index) => (
          <Link key={index} to={data.url}>
            <h2 className="pr--1">{data.label}</h2>
          </Link>
        ))}
      </div>
        <Toggle
          defaultChecked={darkMode}
          className='darkmode-toggle'
          icons={{
            checked: <MoonIcon style={{fill: "yellow"}}/>,
            unchecked: <SunIcon style={{fill: "yellow"}}/>,
          }}
          onChange={() => dispatch(setDarkMode())} />
    </nav>
  )
}

export default connect(state => ({
  darkMode: state.darkMode.darkMode
}), null)(Nav)