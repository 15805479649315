import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav/"
import '../style/colors.scss'
import '../style/layout.scss'
import '../style/main.scss'

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Nav/>
      <main style={{"height": '100%'}}>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
